exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-courses-[course]-js": () => import("./../../../src/pages/courses/[course].js" /* webpackChunkName: "component---src-pages-courses-[course]-js" */),
  "component---src-pages-courses-index-js": () => import("./../../../src/pages/courses/index.js" /* webpackChunkName: "component---src-pages-courses-index-js" */),
  "component---src-pages-custom-payment-js": () => import("./../../../src/pages/customPayment.js" /* webpackChunkName: "component---src-pages-custom-payment-js" */),
  "component---src-pages-fishing-punta-cana-js": () => import("./../../../src/pages/fishing-punta-cana.js" /* webpackChunkName: "component---src-pages-fishing-punta-cana-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-scuba-diving-punta-cana-js": () => import("./../../../src/pages/scuba-diving-punta-cana.js" /* webpackChunkName: "component---src-pages-scuba-diving-punta-cana-js" */),
  "component---src-pages-shark-dive-punta-cana-js": () => import("./../../../src/pages/shark-dive-punta-cana.js" /* webpackChunkName: "component---src-pages-shark-dive-punta-cana-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-sites-index-js": () => import("./../../../src/pages/sites/index.js" /* webpackChunkName: "component---src-pages-sites-index-js" */),
  "component---src-pages-species-index-js": () => import("./../../../src/pages/species/index.js" /* webpackChunkName: "component---src-pages-species-index-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-pages-trips-[trip]-js": () => import("./../../../src/pages/trips/[trip].js" /* webpackChunkName: "component---src-pages-trips-[trip]-js" */),
  "component---src-pages-trips-index-js": () => import("./../../../src/pages/trips/index.js" /* webpackChunkName: "component---src-pages-trips-index-js" */),
  "component---src-pages-tui-[tours]-js": () => import("./../../../src/pages/tui/[tours].js" /* webpackChunkName: "component---src-pages-tui-[tours]-js" */),
  "component---src-pages-tui-sites-index-js": () => import("./../../../src/pages/tui/sites/index.js" /* webpackChunkName: "component---src-pages-tui-sites-index-js" */)
}

